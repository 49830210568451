import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/*
	This implementaiton of Google Analytics requires the
	following snippet be called on every page.
	This is usually added to the index.html.
	Note the environment variable; REACT_APP_GOOGLE_ANALYTICS
	=========================================================
	window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());
	gtag('config', '%REACT_APP_GOOGLE_ANALYTICS%');

*/

export const useTracking = ( trackingId: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS ) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.log('Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.');
        return;
      }
      window.gtag('config', trackingId, { page_path: location.pathname });
    })

    return unlisten
  }, [trackingId, listen])
}