import { Link } from 'react-router-dom'	
import { IoIosArrowForward } 		from "@react-icons/all-files/io/IoIosArrowForward";
import { useLocation } from 'react-router-dom'
	

import './BreadCrumbTrail.scss';

function BCT( {bctArray} ) {
	const location = useLocation();
	// const locationName = location.pathname.replace(/\//g, "");

	
	console.log(bctArray.length);
	if( !bctArray.length ){
		return '';
	}
	// h.map((iter, index) => {
	
	return (
		<section className="breadcrumb-trail">
			<div className="flx box">
				<div className="cell flx flx-dir-row-lock bct-menu">
					{ 	bctArray.map(
							(item, index) => {
								//console.log(`Index is: ${index} and length is ${bctArray.length}`);
								let separator = '';
								if(index > 0 && index <= bctArray.length) {
									separator = <IoIosArrowForward className='bct-separator'/>;
								}
								if( location.pathname === item.bctLink) {
								return <>{separator}<span key={index}>{item.bctLabel}</span></>;
								}
								return <>{separator}<Link to={item.bctLink} key={index}>{item.bctLabel}</Link></>;
							}
						)
					}				
				</div>
			</div>
		</section>
	);
}

export default BCT;