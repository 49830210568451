import React  from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet-async';

const HelmetWrap = ({ page = 'home', location: { pathname } }) => {

	/*
		See copied content in Index.html (in the og tags)
	*/

	// default content for home page
	const businessTitle = 'CODEBASE';
	const domain = 'codebase.com.au';
	let metaTitle = 		'Custom Software, Brand Design, Websites | CODEBASE';
	let metaDescription = 	'CODEBASE specialises in high quality custom software projects. '+
							'We also have a very experienced Brand and Graphic Design team. '+
							'We bring these skills together to build high quality corporate websites. '+
							'To find out more, get in touch.';

	// default path for all pages OTHER THAN home and 404
	let canonicalUrl = 'https://'+domain+pathname; 

	switch (pathname) {
		
		case ('/'):
			//canonicalUrl = 'https://'+domain+'';   REMOVED - Google treat them the same and, in search console, forcibly add the slash so let's mimic that.
			break;

		case '/about/':
			metaTitle = 		'Meticulous Attention to Detail | ' + businessTitle;
			metaDescription = 	'We are passionate about producing the highest quality work. '+
								'When you engage CODEBASE to build custom software you know you are getting the best. ' +
								''+
								''+
								'';
			break;

		case '/contact/':
			metaTitle = 		'Contact us to find out how we can help you. | ' + businessTitle;
			metaDescription = 	'Custom designed and built business software, brand design and corporate websites. Find out how we can help our business. Contact us Now.';
			break;

		case '/software/':
			metaTitle = 		'Need help with custom software development? Ask ' + businessTitle;
			metaDescription = 	'We have been building custom software for decades. We can design, build and maintain custom solutions to your business needs. Talk to us.';
			break;

		case '/graphic-design/':
			metaTitle = 		'Full Brand Design, Pre-Press Artwork preparation | ' + businessTitle;
			metaDescription = 	'We have years of experience creating enduring, versatile and meaningful brand designs that communicate their message and will look engaging for decades to come.';
			break;
			
			case '/de-consult/':
				metaTitle = 		'Brand Study: De-consult | ' + businessTitle;
				metaDescription = 	'One of our favourite brand designs. De-consult work in the mining deconstruction space. Be sure to read the detailed explanation of the graphic devices used.';
				break;
			
			case '/stephen-woolley/':
				metaTitle = 		'Brand Study: Stephen Woolley Physiotherapy | ' + businessTitle;
				metaDescription = 	'Hidden messages and strong colour impact. We worked closely with Stephen to arrive at this strong and multi layered brand.';
				break;

			case '/lifetimeline/':
				metaTitle = 		'Brand Study: LifeTimeLine | ' + businessTitle;
				metaDescription = 	'Summing up the business in one shape. LifeTimeLine is all about telling stories with images. Read more to see why this works';
				break;

			case '/intelligent-information/':
				metaTitle = 		'Brand Study: Intelligent Information | ' + businessTitle;
				metaDescription = 	'We did this one a long time ago - and it still looks great. Read more to see how this logo works on multiple levels.';
				break;

			case '/inmemoriam/':
				metaTitle = 		'Brand Study: In Memoriam | ' + businessTitle;
				metaDescription = 	'Everyone loves elephants! Can you see the hidden letters in this logo? Read on to find out where this shape came from.';
				break;

			case '/asling-street/':
				metaTitle = 		'Brand Study: Asling Street Property Group | ' + businessTitle;
				metaDescription = 	'We love the symbology of the stylised Monopoly houses in this one. It also had another important function. Find out more.';
				break;

			case '/codebase/':
				metaTitle = 		'Brand Study: CODEBASE | ' + businessTitle;
				metaDescription = 	'Simplicity at its best. A wordmark was perfect for a business all about code. Read more to learn why we chose a rust colour.';
				break;

		case '/websites-and-seo/':
			metaTitle = 		'Beautifully designed, company websites and SEO | ' + businessTitle;
			metaDescription = 	'Leveraging our Graphic Design and Software expertise, we can create a beautifully designed company website. Come and check out our examples and contact us.';
			break;

		case '/services/':
			metaTitle = 		'Software | Graphic Design | Websites | ' + businessTitle;
			metaDescription = 	'We have decades of experience delivering carefully designed and built solutions to our clients. Ask what we can solve for you.';
			break;

		case '/sitemap/':
			metaTitle = 		'Shortcut to Support Links and Resources | ' + businessTitle;
			metaDescription = 	'Use our Sitemap to quickly get to our support links or any other content on our system. ' +
								'Want to learn more? Contact us now!';
			break;
			
		case '//':
			// metaTitle = 		'Advice & Expertise to Help You Succeed | ' + businessTitle;
			// metaDescription = 	'We provide personal support to business growth leveraging a diverse background in accounting, ' +
			// 					'finance and business management. Want to learn more? Contact us today!';
			break;
			
		case '/privacy-policy/':
			metaTitle = 		'Privacy Policy - We care about data safety | ' + businessTitle;
			metaDescription = 	'Data you provide to us is considered sensitive and not shared. We know how important confidentiality is and treat your information with care and respect.';
			break;

		default:
			// 404
			metaTitle = 		'Oops! | ' + businessTitle;
			metaDescription = 	'We had a look but we couldn\'t find the page you requested. Try the navigation at the top of the page or contact us if you need help.';
			canonicalUrl = 		'https://'+domain+'/404/'; 
			break;
	}

	return (
		<Helmet>
			<title>{metaTitle}</title>
			<meta name="description" content={metaDescription} />
			<link rel="canonical" href={canonicalUrl} />
		</Helmet>
	)
}

export default withRouter(HelmetWrap);