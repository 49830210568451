// Fires func at most once per interval
const throttle = (func, interval = 200) => {
	let first = true;
	let waiting = false;

	return () => {
		if (first) {
			func();
			first = false;
			return;
		}

		if (!waiting) {
			waiting = true;
			window.setTimeout(() => {
				func();
				waiting = false;
			}, interval);
		}
	};
};

const breakpoints = {
	'mobile-s': 10,
	'mobile-m': 22,
	'mobile-l': 30,
	'mobile-h': 35,
	'mobile-landscape': 40,
	'tablet': 50,
	'desktop-s': 60,
	'desktop-ms': 65,
	'desktop-m': 70,
	'desktop-l': 80,
	'wide': 90,
}

// Mirrors styles/media.scss
const getBreakpoint = () => {
	if (window.matchMedia('(max-width: 20em)').matches) {  				// 320px
		return 'mobile-s';
	} else if (window.matchMedia('(max-width: 23.4375em)').matches) {  	// 375px
		return 'mobile-m';
	} else if (window.matchMedia('(max-width: 30em)').matches) {  		// 480px
		return 'mobile-l';
	} else if (window.matchMedia('(max-width: 36.25em)').matches) {  	// 580px
		return 'mobile-h';
	} else if (window.matchMedia('(max-width: 48em) and (orientation: landscape)').matches) {  // 768px
		return 'mobile-landscape';
	} else if (window.matchMedia('(max-width: 48em)').matches) {  		// 768px
		return 'tablet';
	} else if (window.matchMedia('(max-width: 55em)').matches) {  		//  880px
		return 'desktop-s';
	} else if (window.matchMedia('(max-width: 60em)').matches) {  		//  960px
		return 'desktop-ms';
	} else if (window.matchMedia('(max-width: 64.0625em)').matches) {  	// 1025px
		return 'desktop-m';
	} else if (window.matchMedia('(max-width: 90em)').matches) {  		// 1440px
		return 'desktop-l';
	} else if (window.matchMedia('(max-width: 160em)').matches) {  		// 2560px
		return 'wide';
	}
	return 'desktop-m';
}

const makeUUID = function() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
		return v.toString(16);
	});
}

export {
	throttle,
	breakpoints,
	getBreakpoint,
	makeUUID,
};
