import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import Ribbon from '../../components/Ribbon/Ribbon.js'

import './Software.scss';

function Software() {
	return (
		<main>
			<Ribbon Tag="H1"><span>Trusted</span> Software delivered and managed with <span>meticulous</span> care and attention</Ribbon>
			
			<section className="last">
				<div className="flx box">
					<div className="cell flx-cell">
						<h6 className="center"><span>Software</span> Development</h6>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-cell software-services-img" />
					<div className="cell flx-cell">
						<h2 className="mt-0">Experienced End to End Software Project Delivery</h2>		

						<p>We are a lean company of dedicated professionals who genuinely care about the tools we build for our clients. 
						We have decades of experience helping clients develop their requirements specification, scope project plans and execute to successful delivery.</p> 
				
						<p>Our typical project is deployed to an online environment with customised enterprise hosting or under a Software as a Service (SaaS) model.</p>
						<p>We also have in-house projects such as our SKILLBASE™ Training Platform which falls into the Platform as a Service (PaaS) category.</p>
						<p>Over the years we have delivered projects using various technology stacks including C#, Node.js, ReactJS, Lucee (ColdFusion), Docker and SQL Server.</p>
						<p>We can also audit your existing system and ensure it is properly setup for disaster recovery events and asset accessibility has been addressed.</p>

						<p>Contact us to discuss your requirements and how we can assist you.</p>

						<CallToAction ctaLabel={["Email us for a Chat"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
				</div>
			</section>
		</main>
	);
}

export default Software;