import { React, useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen.js'
import CallToAction from '../../components/CallToAction/CallToAction.js'
import Ribbon from '../../components/Ribbon/Ribbon.js'

import './WebsitesSEO.scss';


function WebsitesSEO() {
	const monitorImageRef = useRef()
	const monitorOnScreen = useOnScreen(monitorImageRef);
	const smartphoneImageRef = useRef()
	const smartphoneOnScreen = useOnScreen(smartphoneImageRef);
	const monitorImageClasses = ['cell', 'flx-cell', 'biggy', 'slideImages'];
	if( monitorOnScreen ){
		monitorImageClasses.push('on-screen');
	}
	const smartphoneImageClasses = ['smalls'];
	if( smartphoneOnScreen ){
		smartphoneImageClasses.push('on-screen');
	}
	return (
		<main>
			<Ribbon Tag="H1"><span>Company Website Design</span> hosting and maintenance.</Ribbon>
			
		{ process.env.NODE_ENV && process.env.NODE_ENV === 'development' &&
			<section className="solid-white marker">
				<div className="box">
				</div>
			</section>}
			<section className="last">
				<div className="flx box">
					<div className="cell flx-cell">
						<h6 className="center"><span>Website</span> Development</h6>					
					</div>
				</div>
				<div className="flx box ">
					<div ref={monitorImageRef} className={monitorImageClasses.join(' ')}>
						<div ref={smartphoneImageRef} className={smartphoneImageClasses.join(' ')} />
					</div>
					<div className="cell flx-cell">
						<h2 className="">Building a Better Online Presence</h2>
						<p>Leveraging our Graphic Design and Software expertise, we can create beautifully designed company websites.</p>

						<p>Websites need to not only be engaging, the need to communicate the information the user is seeking and at the same time speak the language of the search engines.</p>
						<p>We understand the structural requirements the search engines are looking for and can design an engaging and informative online presence for your company.
						We will build in the essential framework to help you get the most out of your search engine optimisation (SEO) and digital marketing spend.</p>
						<p>We host and maintain all of the websites we build and clients know they can email us changes and usually expect those changes to be live within a couple of business hours.</p>
						<CallToAction ctaLabel={["Talk to us about your new website"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
				</div>
			</section>
		</main>
	);
}

export default WebsitesSEO;