import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './utils/ScrollToTop';

import App from './App';

ReactDOM.render(
	<BrowserRouter>
		<HelmetProvider>
			<ScrollToTop>
				<App />
			</ScrollToTop>
		</HelmetProvider>
	</BrowserRouter>,
	document.getElementById('root')
);