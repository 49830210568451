import React from 'react';
import { Link } from 'react-router-dom';
import { VscSourceControl } from "@react-icons/all-files/vsc/VscSourceControl";
import { VscVmActive } 		from "@react-icons/all-files/vsc/VscVmActive";
import { BiPaperPlane } 		from "@react-icons/all-files/bi/BiPaperPlane";
import CustomLink from "../../utils/CustomLink.js";
import './Teasers.scss';

export default function Teasers(props) {

	const classes = [ 'box', 'flx', 'showcase' ];
	if (props.serviceSubMenu) {
		classes.push('service-sub-menu');
	}

	const teaserItems = [
		{	key:		'VscSourceControl',
			coreService:true,
			icon:		VscSourceControl,
			heading:	[ <span key="1">Software</span>, " Projects" ],
			linkLabel:	"More about our Software Services.",
			LinkURL:	"/software/",
			para:		"We have decades of experience delivering highly stable, performant, custom software solutions. "+
						"We don't shortcut code quality and believe getting it right is the best ROI. "+
						"We work in a variety of languages and frameworks to suit the exact needs of your project. "
		},
		{	key:		'BiPaperPlane',
			coreService:true,
			icon:		BiPaperPlane,
			heading:	[ <span key="2">Graphic </span>, " Design" ],
			linkLabel:	"Click here to find out more.",
			LinkURL:	"/graphic-design/",
			para:		"We are passionate about the brands we create. Our clients love their logo designs even years after they have been delivered. "+
						"We strive to create meaningful, long lasting and versatile brand designs. "+
						"We can also prepare pre-press files and see jobs though to the final print. "						
		},
		{	key:		'VscVmActive',
			coreService:false,
			icon:		VscVmActive,
			heading:	[ <span key="3">Websites  </span>, " & SEO " ],
			linkLabel:	"Call us to discuss how we can assist.",
			LinkURL:	"/websites-and-seo/",
			para:		"Designing websites is the perfect blend of our programming and design expertise. "+
						"We can ensure your website is hand crafted to communicate your business message and "+
						"get the most out of your Search Engine Optimisation (SEO) and Digital Marketing investment."

		}
	];

	const teaserContent = teaserItems.length ? teaserItems.map(teaserItem =>
		(teaserItem.coreService || !props.serviceSubMenu) &&
			<CustomLink
				tag='div'
				to={teaserItem.LinkURL}
				className="showcase-panel flx-cell"
				key={teaserItem.key}
				>
				<div className="icon-box">
					<teaserItem.icon className="icon-teaser" />
				</div>
				<div className="text-box">
					<h2>{teaserItem.heading}</h2>
					<p>{teaserItem.para}</p>
					<Link to={teaserItem.LinkURL} >{teaserItem.linkLabel}</Link>
				</div>
			</CustomLink>
	) : '...';

	return (
		<section className="white-bg">
			<div className={classes.join(' ')}>
				{teaserContent}
			</div>
		</section>
	)
};