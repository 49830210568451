
import { VscSourceControl } from "@react-icons/all-files/vsc/VscSourceControl";
import { VscVmActive } 		from "@react-icons/all-files/vsc/VscVmActive";
import { BiPaperPlane } 		from "@react-icons/all-files/bi/BiPaperPlane";


const MenuItems = [
	{
		itemKey: 'services',
		label: 'Services',
		path: '/services/',
		clickable: false,
		openByDefault: true,
		subMenuItems: [
			{
				title: 'Software Development',
				path: '/software/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item',
				icon: VscSourceControl
			},
			{
				title: 'Graphic Design',
				path: '/graphic-design/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item',
				icon: BiPaperPlane
			},
			{
				title: 'Websites & SEO',
				path: '/websites-and-seo/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item',
				icon: VscVmActive
			}
		]
	},
	{
		itemKey: 'about',
		label: 'About',
		path: '/about/',
		clickable: true,
		subMenuItems: []
	},
	// {
	// 	itemKey: 'other',
	// 	label: 'Other',
	// 	path: '/services/',
	// 	clickable: false,
	// 	openByDefault: true,
	// 	subMenuItems: [
	// 		{
	// 			title: 'Software Development',
	// 			path: '/software/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		},
	// 		{
	// 			title: 'Management Consulting',
	// 			path: '/management-consulting/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		},
	// 		{
	// 			title: 'Corporate Governance',
	// 			path: '/corporate-governance/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		}
	// 	]
	// },
	{
		itemKey: 'contact',
		label: 'Contact',
		path: '/contact/',
		clickable: true,
		subMenuItems: []
	}
]
export default MenuItems;