import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

function Dropdown({ itemKey, label, path, clickable, subMenuItems, isHamburger, openByDefault, currentLocation }) {

	const [ dropMenuOpen, setDropMenuOpen ] = useState(isHamburger && openByDefault);
	const [ mouseIsOver, setMouseIsOver ] = useState();
	
	useEffect(() => {
		setDropMenuOpen( (mouseIsOver || dropMenuOpen) ? true : false);
	}, [dropMenuOpen,mouseIsOver,itemKey]);

	const onMouseEnter = () => {
		if (!window.Modernizr.touchevents || !isHamburger) {		// if env is NOT touch -OR- NOT isHamburger
			setMouseIsOver(true);
		}
	}
	const onMouseLeave = () => {
		if (!window.Modernizr.touchevents || !isHamburger) {
			setDropMenuOpen(false);
			setMouseIsOver(false);
		}
	}
	const onDropMenuClick = (event) => {
		event.stopPropagation();
		if (dropMenuOpen) {							// required to be in this format for some obscure reason
			setDropMenuOpen(false);
		} else {
			setDropMenuOpen(true);
		}
	}	
	const handleSubMenuItemClick = (event) => {
		event.stopPropagation();
	}

	const ConditionalLink = clickable ? Link : 'div';
	
	return (
		<li key={itemKey} className='menu-item'
			onMouseLeave={isHamburger ? undefined : onMouseLeave} 		// Important this is here so when mouse leaves any of the Li or the contained UL-LI, it registers to close
			>
			<ConditionalLink
				to={path}
				className='menu-item-link'
				onMouseEnter={isHamburger ? undefined : onMouseEnter} 
				onClick={onDropMenuClick}
				>{label}
			</ConditionalLink>
			{ dropMenuOpen && subMenuItems && subMenuItems.length > 0 &&
				<ul className='sub-menu'>
					{ subMenuItems.map((iter, index) => {
						const classy = [iter.itemClassName];
						if(currentLocation === iter.path){
							classy.push('active')
						}
						return (
							<li key={index+100} className={classy.join(" ")} >
								<Link className={iter.linkClassName} to={iter.path} onClick={handleSubMenuItemClick}>
									{!isHamburger && <iter.icon className="dropdown-icon" />}{iter.title}
								</Link>
							</li>
							)
					})}
				</ul>
			}
		</li>
	)
}

export default Dropdown;