import React from 'react';
import { Link } from 'react-router-dom'

import './CallToAction.scss';

function CallToAction(props) {

	const classes = [ 'cta' ];
	if (props.className) {
		classes.push(props.className);
	}
	const { ctaLabel = 'Contact Us', ctaDestination =  '/contact/' } = props;

	return (
		<div className={classes.join(' ')}>
			<Link to={ctaDestination} className="cta--link">{ctaLabel}</Link>
		</div>
	);
}
export default CallToAction;