import React from 'react';
import { Link } from 'react-router-dom'
import Ribbon from '../../components/Ribbon/Ribbon.js'
import './FourZeroFour.scss';

function PrivacyPolicy() {
	return (
		<main>
			<Ribbon Tag="H6"><span>Hmmm</span> <span className='normal'>We couldn't find what you requested.</span></Ribbon>
			
			<section className="last">
				<div className="flx box">
					<div className="cell">
						<h1><span>404</span> Content not found</h1>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell cell--two-thirds flx-cell ">
						<p>We had a look but we couldn't find the page you requested.</p>
						<p>Try the navigation at the top of the page or <Link to="/contact-us/">contact</Link> us if you need help.</p>

					</div>
				</div>
			</section>
		</main>
	);
}

export default PrivacyPolicy;