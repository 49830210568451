import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import Ribbon from '../../components/Ribbon/Ribbon.js'
import Brands from '../../components/Carousels/Brands.js';

import './GraphicDesign.scss';

function GraphicDesign(props) {
	return (
		<main>
			<Ribbon Tag="H1"><span>Brand Design</span>, Pre-press artwork preparation <span>Graphic</span> Design Consulting</Ribbon>

			<section className=" pb-2">
				<div className="box flx flx--centre flx-dir-col-lock">
					<div className="cell flx-cell">
						<h6 className="center">Graphic <span>Design</span></h6>
					</div>
				</div>
				<div className="box flx flx--centre flx-dir-col-lock">
					<div className="cell flx-cell">
						<p className="center">We are super passionate about brand design. We love building ideas that communicate your company message with intelligent and timeless graphic devices.
						Check out some of our previous work below and click on each logo to see a breakdown of each design.</p>
						<div className="center"><CallToAction ctaLabel={["Let's Talk Branding"]} className="cta--long cta--focus-bright--focus" ctaDestination='/contact/'/>	</div>
					</div>
				</div>
				<Brands breakpoint={props.breakpoint} />
			</section>



		</main>
	);
}

export default GraphicDesign;