import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import BreadCrumbTrail from '../../components/BreadCrumbTrail/BreadCrumbTrail.js'
import { useLocation } from 'react-router-dom'


import {ReactComponent as DeConsultLogo} 		from "../../svg/logos/De-Consult-Logo-V09.svg";
import {ReactComponent as StephenWoolleyLogo} 	from "../../svg/logos/Stephen-Woolley-Logo-V12.svg";
import {ReactComponent as IIAULogo} 			from "../../svg/logos/iiau-Logo-V04.svg";
import {ReactComponent as IMGLogo} 				from "../../svg/logos/IMG-Plecyderm-V03.svg";
import {ReactComponent as LTLLogo} 				from "../../svg/logos/LifeTimeLine-Slide-Bubble-V01-Vertical.svg";
import {ReactComponent as AlsingStreetLogo} 	from "../../svg/logos/Asling-Street-Logo-V03.svg";
import {ReactComponent as Tax8Logo} 			from "../../svg/logos/TAX8-Logo-V01.svg";
import Logo 									from '../../components/Logo';

import './GraphicDesign.scss';



function BrandStudy() {
	const location = useLocation();
	const locationName = location.pathname.replace(/\//g, "");
	// console.log(`DCT ${locationName}`);
	const brandData = {
		'de-consult': {
			brandLabel: 		'De-Consult',
			brandHeading: 		<h1><span>De-Consult</span></h1>,
			brandSubHeading:	<h2>The central device is invisible</h2>,
			brandComponent:		DeConsultLogo ,
			brandDescription: (	<>
									<p>De-Consult work in the mine deconstruction and environmental restoration space.</p>
									<p>They came to us with no specific requirements.</p>
									<p>We thought a lot about the nature of their business and provided a logo that perfectly represents the two main aspects of their work.</p>
									<p>The centrepiece is an invisible cube which represents the deconstructed mine. Surrounding the empty cube are its parts, now returned to the sky, in blue, and nature, in green.</p>
									<p>The blue and green elements also form the 'D' & 'C' initials of the company and are created from triangles reflecting one of the strongest engineering shapes.</p>								
									<p>We absolutely love the symbology in this logo. Will, from De-Consult, admitted to being unsure about it initially. 
									This is pretty normal. Clients need to get used to a brand. They are designed for the long haul.
									We asked Will to sit with it a while and he eventually called us back to express how much he loved it (more than once).</p>
								</>
								)
		},
		'stephen-woolley': {
			brandLabel: 		'Stephen Woolley',
			brandHeading: 		<h1><span>Stephen Woolley</span></h1>,
			brandSubHeading: 	<h2>Heading in the right direction</h2>,
			brandComponent:		StephenWoolleyLogo,
			brandDescription: (<>
								<p>Stephen Woolley Occupational Therapy specialises in providing Medico Legal reports and workplace assessments.</p>
								<p>They needed a new brand to represent their company as it expanded. We researched their principal as well as the company's professional vertical and carefully chose a very strong, 
									confident colour to easily differentiate the business.</p>
								<p>As Stephen Woolley is integral to the company, we arrived at an arrangement of his initials in the final brand. Carefully arranged on the base and near-right face of a cube with 
									partially transparent letters that overlap to form a right-arrow as well as an elevated wing device all alluding to positive, forward progress.</p>
								</>
								)
		},
		'intelligent-information': {
			brandLabel: 		'Intelligent Information',
			brandHeading: 		<h1><span>Intelligent Information</span></h1>,
			brandSubHeading: 	<h2>The Intelligence behind the information</h2>,
			brandComponent:		IIAULogo,
			brandDescription: (<>
								<p>Intelligent Information provide corporate intelligence services.</p>
								<p>We incorporated the two 'i's of their name to represent the importance of backing business decisions with strong, well organised information. We aligned the serifs of the two 'i's as well as the
									base serif perimeter of the shadow with the stem of the main 'i' to reinforce this concept.</p>
								<p>The sizing of the shadow was specifically created to imitate the result of a near light source on the main device creating a larger shadow beneath it alluding to the 'shining of light' on information decisions.</p>
								</>
								)
		},
		'in-memoriam': {
			brandLabel: 		'In Memoriam',
			brandHeading: 		<h1><span>In Memoriam</span></h1>,
			brandSubHeading: 	<h2>Elephants never forget</h2>,
			brandComponent:		IMGLogo,
			brandDescription: (<>
								<p>In Memoriam Group wanted a elegant and likeable logo for a vertical that can often be difficult to represent.</p>
								<p>We looked at the principle role of their products, helping people remember, and we naturally associated the elephant, a motif universally loved.</p>
								<p>We created this highly stylised device that will render well in any context. We also incorporated the ‘i’ and the ‘m’ using negative space.</p>
								<p>We chose a sky blue as it provides a positive and uplifting aspect. The font chosen was specifically to highlight the correct spelling of the company name. 
									Using an 'a' with the extra swash differentiated a simple bowl 'a' from a common misspelling using 'u'.</p>
								</>
								)
		},
		'lifetimeline': {
			brandLabel: 		'LifeTimeLine',
			brandHeading: 		<h1><span>LifeTimeLine­®</span></h1>,
			brandSubHeading: 	<h2>When awkward shapes work</h2>,
			brandComponent:		LTLLogo,
			brandDescription: (<>
								<p>A part of In Memoriam Group, LifeTimeLine wanted a logo that spoke to their specific product and would present well alongside their parent company brand.</p>
								<p>The core of their product allows users to upload images and document the contents. We combined the dimensions of a vintage slide frame, the shape of which forms a 
									slightly awkward style due to the unequal dimensions, with a text bubble tail to communicate speaking with images.</p>
								<p>The font chose was a strong, confident, poster style to promote the showcase nature of their product.</p>
								</>
								)
		},
		'asling-street': {
			brandLabel: 		'Asling Street',
			brandHeading: 		<h1><span>Asling Street</span></h1>,
			brandSubHeading: 	<h2>Home is a bouncy castle</h2>,
			brandComponent:		AlsingStreetLogo,
			brandDescription: (<>
								<p>A private property management group, Asling Street wanted a fresh brand to incorporate in their communications with clients. They also wanted a design that could be used as a corporate seal.</p>
								<p>Predominantly focused on small housing collectives, we combined the gentle shape of a lower case 'a' with a sweeping pathway joining two houses.</p>
								<p>The two houses, nested in the counter and swash of the 'a' are simple, stylised designs, almost an inflated shape.</p>
								<p>The colour was chosen to be striking and reminiscent of the houses from the Monopoly board game.</p>
								</>
								)
		},
		'codebase': {
			brandLabel: 		'CODEBASE',
			brandHeading: 		<h1><span>CODEBASE®</span></h1>,
			brandSubHeading: 	<h2>Double take</h2>,
			brandComponent:		Logo,
			brandDescription: (<>
								<p>We don't often use word-marks for logos but felt the symbology worked well here as it highlights a double meaning of the word codebase. In one case referring to the collection 
								of code files that make up an applications and the separate use of code and base indicating we are the centre of the universe when it comes to code. ;)</p>
								<p>We were also able to devise a succinct slogan that perfectly sums us the CODEBASE approach to working with clients.</p>
							
								</>
								)
		},
		'tax8': {
			brandLabel: 		'TAX8',
			brandHeading: 		<h1><span>TAX8</span></h1>,
			brandSubHeading: 	<h2>A Personal Connection</h2>,
			brandComponent:		Tax8Logo,
			brandDescription: (<>
								<p>A perfectly short brand emphasising simplicity and clarity in an often complex field.</p>
								<p>The principal, Kate, has, for many years, signed her name as ‘K8’ so we took advantage of that to create 
								TAX8 and leveraged the greater than and less than symbols to divide the 'X' thereby forming the 'K' and placing ‘K8’ in the brand.</p>
							
								</>
								)
		}
	};
	// const brandArray = [
	// 	{ brandLink: 'de-consult', brandLabel: 'De-Consult' }
	// 	, { brandLink: 'stephen-woolley', brandLabel: 'Stephen Woolley' }
	// 	, { brandLink: 'intelligent-information', brandLabel: 'Intelligent Information' }
	// 	, { brandLink: 'in-memoriam', brandLabel: 'In Memoriam' }
	// 	, { brandLink: 'lifetimeline', brandLabel: 'LifeTimeLine' }
	// 	, { brandLink: 'asling-street', brandLabel: 'Asling Street' }
	// 	, { brandLink: 'codebase', brandLabel: 'CODEBASE' }
	// ];


	if( !(locationName in brandData)) {
		return <h1>Content Not Found</h1>;
	}
	const BrandComponent = brandData[locationName].brandComponent;
	const bctArray = [ { bctLink: '/graphic-design/', bctLabel: 'Graphic Design' }, { bctLink: `/${locationName}/`, bctLabel: brandData[locationName].brandLabel } ]

	return (
		<main>
			<BreadCrumbTrail bctArray={bctArray} />

			<section className="last">
				<div className="flx box">
					<div className="cell">
						{brandData[locationName].brandHeading}
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-cell flx-order-2nd-in-column ">
						{brandData[locationName].brandSubHeading}	
						{brandData[locationName].brandDescription}
						<CallToAction ctaLabel={["Talk to us about your brand needs"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
					<div className="cell flx-cell brand-study-box">
						<BrandComponent  className="brand-study-img" />
					</div>
				</div>
			</section>
		</main>
	);
}

export default BrandStudy;