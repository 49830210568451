import React, { useState, useLayoutEffect, useEffect, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import HelmetWrap from './utils/HelmetWrap.js'
import * as utils from './utils/general.js';

import './App.scss';

import Header			from './components/Header/Header';
import Banner 			from './components/Banner/Banner.jsx';

import { useTracking }	from './hooks/useTracking.js'
import Loader 			from './components/Loader/Loader.jsx'

// import Home				from '../pages/Home/Home';
import GraphicDesign		from './pages/GraphicDesign/GraphicDesign';
	// import DeConsult		from './pages/GraphicDesign/DeConsult';
	// import StephenWoolley		from './pages/GraphicDesign/StephenWoolley';
	// import LifeTimeLine		from './pages/GraphicDesign/LifeTimeLine';
	// import IntelligentInfo		from './pages/GraphicDesign/IntelligentInfo';
	// import InMemoriam		from './pages/GraphicDesign/InMemoriam';
	// import AslingStreet		from './pages/GraphicDesign/AslingStreet';
	// import Codebase		from './pages/GraphicDesign/Codebase';
	import BrandStudy		from './pages/GraphicDesign/BrandStudy';

import WebsitesSEO		from './pages/WebsitesSEO/WebsitesSEO';
import Software			from './pages/Software/Software';
import Tax8				from './pages/GraphicDesign/Tax8';
import About			from './pages/About/About';
// import Contact			from '../pages/Contact/Contact';
import Services			from './pages/Services/Services';
import PrivacyPolicy	from './pages/PrivacyPolicy/PrivacyPolicy';
import Sitemap			from './pages/Sitemap/Sitemap';
import FourZeroFour		from './pages/FourZeroFour/FourZeroFour';
// import Footer 			from '../components/Footer/Footer';
// import Scroller 		from '../components/Scroller/Scroller.js';

const Home = React.lazy(() => import('./pages/Home/Home'));
// const GraphicDesign = React.lazy(() => import('../pages/GraphicDesign/GraphicDesign'));
// const WebsitesSEO = React.lazy(() => import('../pages/WebsitesSEO/WebsitesSEO'));
// const Software = React.lazy(() => import('../pages/Software/Software'));
// const About = React.lazy(() => import('../pages/About/About'));
const Contact = React.lazy(() => import('./pages/Contact/Contact'));
// const Services = React.lazy(() => import('../pages/Services/Services'));
// const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
// const Sitemap = React.lazy(() => import('../pages/Sitemap/Sitemap'));
// const FourZeroFour = React.lazy(() => import('../pages/FourZeroFour/FourZeroFour'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const Scroller = React.lazy(() => import('./components/Scroller/Scroller'));


function App({ location }) {
	useTracking();
	useLayoutEffect(() => {
		window.addEventListener('scroll', handleHeader);
		return () => window.removeEventListener('scroll', handleHeader);
	});
	useLayoutEffect(() => {
		window.addEventListener('resize', resizeHandler);							// Run the resize through a throttle
		return () => window.removeEventListener('resize', resizeHandler);
	});
	useEffect(() => {																// This is an onLoad to determine header if on mobile etc.
		handleHeader();
		handleBreakpoint();
	}, []);

	const [headerIsSmall, setHeaderIsSmall] = useState(  window.pageYOffset < 70 && window.innerWidth >= 480  ? false : true  ); // LIGHTHOUSE default header size to avoid Cumulative Layout Shift (CLS). Technically should include overflow check but LIGHTHOUSE is not scrolling so not relevant.
	const [pageScrolled, setPageScrolled] = useState(false);
	const [pageIsNarrow, setPageIsNarrow] = useState(false);
	const [isHamburger, setIsHamburger] = useState(false);
	const [breakpoint, setBreakpoint] = useState({ level: 70, label: 'desktop-m'});
	const [sliderStart] = useState(Math.floor(Math.random() * 6));					// randomise start slide only on hydration (so it needs to be here in the App.js)

	const resizeHandler = utils.throttle(() => {
		window.dispatchEvent(new CustomEvent('RESIZE'));							// as we have hijacked the resize event - ignoring ultra short repeats, (re) trigger it manually
		handleHeader();
		handleBreakpoint();
	});

	const handleBreakpoint = () => {
		setBreakpoint({
			label: utils.getBreakpoint(),
			level: utils.breakpoints[utils.getBreakpoint()]
		});
	}

	const handleHeader = () => {
		const body = document.body,
			  html = document.documentElement;	
		const bodyHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );	
		const winHeight = window.innerHeight;
		const overflowPixels = Math.max(0, bodyHeight-winHeight);

		// TASK: change to use refs
		if ( window.pageYOffset < 5 ) {
			document.getElementById('app').classList.remove('header--shadow');
		}
		if ( window.pageYOffset >= 5 ) {
			document.getElementById('app').classList.add('header--shadow');
		}
		if ( window.pageYOffset < 100 ) {
			setPageScrolled(false);
		}
		if ( window.pageYOffset >= 100 ) {
			setPageScrolled(true);
		}
		if ( window.innerWidth < 480 ) {
			setPageIsNarrow(true);
		}
		if ( window.innerWidth >= 480 ) {
			setPageIsNarrow(false);
		}
		if ( window.innerWidth < 880 ) { // SETTING--hamburger
			setIsHamburger(true);
		}
		if ( window.innerWidth >= 880 ) {
			setIsHamburger(false);
		}
		if ( (overflowPixels > 200 && window.pageYOffset >= 70) || window.innerWidth < 480 ) {
			setHeaderIsSmall(true);
		}
		if ( window.pageYOffset < 70 && window.innerWidth >= 480 ) {
			setHeaderIsSmall(false);
		}
	}

	const classes = [ 'App ' ];
	if (!headerIsSmall) {
		classes.push('header--big');
	} 
	if (pageScrolled) {
		classes.push('page--scrolled');
	}
	if (pageIsNarrow) {
		classes.push('page--thin');
	}
	if (isHamburger) {
		classes.push('header--hamburger');
	}


	return (
		<div className={classes.join(' ')} id="app">
			<HelmetWrap />
			<Header isHamburger={isHamburger} />
			<div className="non-header">
				<Banner pathName={location.pathname} sliderStart={sliderStart} />
				<Suspense fallback={<Loader />}>
					<Switch>
						<Route
							exact
							path="/"
							render={ routeProps => { return <Home {...routeProps} breakpoint={breakpoint} />; } }
							/>
						<Route path='/services/' component={Services}/>
							<Route path='/software/' component={Software}/>



							<Route path='/tax8/' component={Tax8}/>
							
							<Route path="/graphic-design/" render={ routeProps => { return <GraphicDesign {...routeProps} breakpoint={breakpoint} />; } } />




								<Route path='/de-consult/' component={BrandStudy}/>	
								<Route path='/stephen-woolley/' component={BrandStudy}/>
								<Route path='/lifetimeline/' component={BrandStudy}/>
								<Route path='/intelligent-information/' component={BrandStudy}/>
								<Route path='/in-memoriam/' component={BrandStudy}/>
								<Route path='/asling-street/' component={BrandStudy}/>
								<Route path='/codebase/' component={BrandStudy}/>
								{/* <Route path='/tax8/' component={BrandStudy}/> */}




							<Route path='/websites-and-seo/' component={WebsitesSEO}/>
						<Route path='/about/' component={About}/>
						<Route
							path="/contact/"
							render={ routeProps => { return <Contact {...routeProps} pageIsNarrow={pageIsNarrow} />; } }
							/>
						<Route path='/privacy-policy/' component={PrivacyPolicy}/>
						<Route path='/sitemap/' component={Sitemap}/>
						<Route path='*' component={FourZeroFour}/>
					</Switch>
					<Footer />
					<Scroller />
				</Suspense>
			</div>
		</div>
	);
}

export default withRouter(App);
