import React from 'react';
import './Logo.scss';
import { makeUUID } from '../../utils/general.js';
// see https://css-tricks.com/accessible-svgs/

function Logo(props) {

	const classes = [ 'brand-box' ];
	if (props.className) {
		classes.push(props.className);
	}

	if (props.ariaContext) {
		var ariaTitleID = makeUUID();
		var ariaTitleContent = 'Click CODEBASE Logo to go Home';
	}

	return (
		<div className={classes.join(' ')}>

			<svg
				className="brand-logo"
				version="1.1"
				id="CODEBASE-Logo"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 193.25098 31.55078"			
				aria-labelledby={ariaTitleID}
				>
			{ariaTitleID && <title id={ariaTitleID}>{ariaTitleContent}</title>}
			<g>
				<g>
					<path className="registered-trademark-symbol" d="M192.82324,4.54541c0,0.69043-0.16992,1.33154-0.51172,1.92334c-0.3418,0.58984-0.81055,1.05859-1.40625,1.4043 s-1.23438,0.51758-1.91406,0.51758c-0.68164,0-1.31836-0.17188-1.91406-0.51563s-1.06641-0.81152-1.4082-1.4043 c-0.34375-0.59326-0.51563-1.22803-0.51563-1.90576c0-0.68408,0.17188-1.32568,0.51563-1.9248 c0.3418-0.59961,0.81055-1.07031,1.40625-1.41211c0.59375-0.34229,1.23242-0.51318,1.91602-0.51318 s1.32031,0.17041,1.91016,0.51074c0.5918,0.34033,1.05859,0.81006,1.4043,1.40967 C192.65137,3.23438,192.82324,3.87109,192.82324,4.54541z M192.29199,4.55029c0-0.58643-0.14844-1.1377-0.44531-1.65381 c-0.29492-0.5166-0.69727-0.92285-1.20508-1.21924s-1.05469-0.44482-1.64063-0.44482c-0.58984,0-1.14258,0.14844-1.6582,0.44482 s-0.92188,0.70264-1.2168,1.21924c-0.29297,0.51611-0.44141,1.0708-0.44141,1.66357c0,0.58984,0.14844,1.14014,0.44141,1.65088 c0.29492,0.51172,0.70117,0.91602,1.21875,1.21289s1.07031,0.44434,1.65625,0.44434c0.58203,0,1.12891-0.14941,1.64063-0.44727 c0.50977-0.29785,0.91406-0.70215,1.20703-1.21191C192.14355,5.69922,192.29199,5.14648,192.29199,4.55029z M187.39355,2.31152 h1.8418c0.42969,0,0.73828,0.04297,0.92773,0.12939s0.3418,0.22754,0.46094,0.42285 c0.11914,0.1958,0.17773,0.42383,0.17773,0.68457c0,0.22461-0.04102,0.41846-0.12695,0.58154 c-0.06445,0.12061-0.20703,0.25244-0.42383,0.396c0.19922,0.09766,0.33203,0.21094,0.40039,0.33984 s0.10156,0.34961,0.10156,0.66211v0.49854c0,0.23535,0.05273,0.43066,0.15625,0.58691l0.07422,0.11719h-0.78125 c-0.05078-0.18262-0.08594-0.40039-0.10742-0.65137l-0.03516-0.54297c-0.01172-0.19922-0.04492-0.33984-0.0957-0.42139 c-0.04883-0.08154-0.12109-0.14014-0.21289-0.17627c-0.0918-0.03564-0.27734-0.05371-0.55273-0.05371h-1.10547v1.8457h-0.69922 V2.31152z M188.09277,2.94629v1.30859h1.08398c0.32813,0,0.55078-0.0376,0.66797-0.1123 c0.17383-0.10742,0.25977-0.28955,0.25977-0.54688c0-0.22461-0.0625-0.38916-0.18555-0.49316 c-0.125-0.104-0.35742-0.15625-0.69922-0.15625H188.09277z"/>
				</g>
				<g>
					<path className="logo-fg" d="M12.40186,0c3.27295,0,6.27588,1.08057,8.46777,2.79248l-2.73242,2.37158 c-0.39063-0.27002-2.43213-2.0415-5.25488-2.0415c-4.65479,0-5.22461,4.59424-5.22461,7.17627 c0,2.6123,0.56982,7.20703,5.22461,7.20703c2.94287,0,4.89404-1.83203,5.25488-2.07227l2.73242,2.37305 c-2.19189,1.74023-5.19482,2.82227-8.46777,2.82227C5.52539,20.62891,0,16.48438,0,10.29883C0,4.0835,5.52539,0,12.40186,0z"/>
					<path className="logo-fg" d="M34.1416,0c6.57568,0,11.86084,4.0835,11.86084,10.29883c0,6.27637-5.28516,10.33008-11.86084,10.33008 S22.28076,16.5752,22.28076,10.29883C22.28076,4.0835,27.56592,0,34.1416,0z M34.1416,17.89648 c3.21289,0,4.32422-3.96387,4.32422-8.22754c0-4.32422-1.92188-7.1167-4.32422-7.1167s-4.29443,2.79248-4.29443,7.1167 C29.84717,13.93262,30.92822,17.89648,34.1416,17.89648z"/>
					<path className="logo-fg" d="M49.87549,19.99805V0.63037h9.27881c5.5249,0,11.34961,2.40234,11.34961,9.0083 c0,6.60547-5.67432,10.35938-11.34961,10.35938H49.87549z M57.86279,17.05566c3.15283,0,5.375-1.92188,5.375-7.41699 c0-4.17383-2.22217-6.06592-5.375-6.06592h-0.99072v13.48291H57.86279z"/>
					<path className="logo-fg" d="M81.43359,3.81299v4.7749h8.25879v3.18262h-8.25879v5.04492h9.33984v0.24023l-1.32129,2.94238H74.37793 V0.63037h14.02344l1.29102,2.94238v0.24023H81.43359z"/>
				</g>
				<g>
					<path className="logo-bg" d="M107.56641,0.63037c3.39355,0,7.53613,0.63037,7.53613,4.47412c0,3.39355-3.7832,4.38379-4.26367,4.53418 c0.45117,0.06055,5.01563,0.66016,5.01563,4.98438c0,4.56445-4.77539,5.375-7.62695,5.375H96.24609V0.63037H107.56641z  M103.24219,9.36816c3.60352,0,4.71387-1.38086,4.71387-3.12305c0-1.56152-1.14063-2.67236-3.00195-2.67236h-1.71191V9.36816z  M105.10449,17.05566c1.38086,0,3.0918-0.33105,3.0918-2.70313c0-2.7627-2.55176-2.79199-3.7832-2.79199h-1.1709v5.49512 H105.10449z"/>
					<path className="logo-bg" d="M123.20996,16.48438l-1.29102,3.51367h-4.80469v-0.24023l8.04883-19.12744h7.11523l7.53711,19.12744v0.24023h-7.68555 l-1.32227-3.51367H123.20996z M129.66699,13.3916l-2.55273-7.4165l-2.79297,7.4165H129.66699z"/>
					<path className="logo-bg" d="M160.26465,3.0625l-2.70313,2.22168c-1.5918-1.44092-4.38477-2.22168-5.91602-2.22168 c-1.56055,0-3.42188,0.30029-3.42188,1.59131c0,1.41162,2.16211,1.56104,5.61523,2.55225 c3.0625,0.87012,7.50781,2.40234,7.50781,6.72656c0,4.74414-4.92578,6.69629-10.06055,6.69629 c-5.19531,0-8.4668-2.2832-9.72852-3.81348l2.79297-2.34277c1.38086,1.41211,4.32227,3.06348,6.93555,3.06348 c1.02148,0,3.45313-0.12012,3.45313-1.47168c0-1.71191-2.73242-2.10156-5.52539-2.91309 c-2.70313-0.78027-7.23633-1.98145-7.23633-6.30566c0-5.43506,5.70508-6.81592,9.57813-6.81592 C155.43066,0.02979,158.04199,1.02051,160.26465,3.0625z"/>
					<path className="logo-bg" d="M172.36426,3.81299v4.7749h8.25781v3.18262h-8.25781v5.04492h9.33789v0.24023l-1.32031,2.94238h-15.07227V0.63037 h14.02148l1.29102,2.94238v0.24023H172.36426z"/>
				</g>
				<g>
					<path className="masthed-byline" d="M98.93457,23.1748c0.97852,0,1.79785,0.32715,2.45703,0.97949c0.66016,0.6543,0.99023,1.45801,0.99023,2.41309 c0,0.94629-0.33008,1.74902-0.98828,2.4082s-1.45996,0.98926-2.40625,0.98926c-0.95898,0-1.76855-0.32813-2.42773-0.98438 c-0.66016-0.65625-0.99023-1.45117-0.99023-2.38281c0-0.62109,0.15039-1.19629,0.45117-1.72656s0.70996-0.94531,1.22852-1.24609 C97.76855,23.3252,98.33008,23.1748,98.93457,23.1748z M98.96191,23.80273c-0.47852,0-0.93164,0.125-1.35938,0.375 c-0.42871,0.24805-0.76367,0.58398-1.00391,1.00586c-0.24023,0.42285-0.36133,0.89355-0.36133,1.41211 c0,0.76855,0.2666,1.41699,0.79883,1.94629c0.5332,0.5293,1.1748,0.79395,1.92578,0.79395 c0.50195,0,0.9668-0.12109,1.39355-0.36523c0.42676-0.24316,0.75977-0.57617,0.99902-0.99805s0.35938-0.8916,0.35938-1.4082 c0-0.51172-0.12012-0.97656-0.35938-1.39063c-0.23926-0.41602-0.57617-0.74805-1.00977-0.99609 C99.91016,23.92773,99.4502,23.80273,98.96191,23.80273z"/>
					<path className="masthed-byline" d="M103.49707,25.02051h0.61523v2.23242c0,0.54492,0.0293,0.91992,0.08789,1.125 c0.08887,0.29297,0.25684,0.52441,0.50391,0.69434c0.24805,0.16992,0.54395,0.25488,0.88672,0.25488 c0.34375,0,0.63477-0.08301,0.875-0.24902c0.23828-0.16406,0.40332-0.38281,0.49414-0.65234 c0.0625-0.18359,0.09277-0.5752,0.09277-1.17285v-2.23242h0.62793v2.34668c0,0.65918-0.07617,1.15625-0.23047,1.49023 s-0.38477,0.5957-0.69336,0.7832c-0.31055,0.18945-0.69727,0.28418-1.16309,0.28418s-0.85449-0.09473-1.16699-0.28418 c-0.3125-0.1875-0.54492-0.45117-0.69922-0.79102c-0.15234-0.33789-0.23047-0.84668-0.23047-1.52637V25.02051z"/>
					<path className="masthed-byline" d="M108.81543,25.02051h0.62793v0.69824c0.1875-0.27539,0.38574-0.48047,0.59277-0.61719 c0.20898-0.13672,0.42578-0.2041,0.65039-0.2041c0.16992,0,0.35156,0.05371,0.54492,0.16309l-0.32031,0.51758 c-0.12891-0.05469-0.2373-0.08301-0.3252-0.08301c-0.20508,0-0.40332,0.08496-0.59277,0.25293 c-0.19141,0.16797-0.33594,0.42969-0.43555,0.78418c-0.07617,0.27246-0.11426,0.82324-0.11426,1.65234v1.61719h-0.62793V25.02051z "/>
					<path className="masthed-byline" d="M118.8877,26.01367l-0.48828,0.30273c-0.42188-0.55859-0.99609-0.83887-1.72559-0.83887 c-0.58203,0-1.06543,0.1875-1.4502,0.56152c-0.38477,0.375-0.57715,0.83008-0.57715,1.36523 c0,0.34863,0.08887,0.67578,0.26563,0.9834s0.41992,0.5459,0.72852,0.71582s0.65527,0.25391,1.03711,0.25391 c0.7041,0,1.27637-0.2793,1.72168-0.83887l0.48828,0.32129c-0.22852,0.34375-0.53516,0.61133-0.92188,0.80078 c-0.38477,0.18945-0.82324,0.28418-1.31445,0.28418c-0.75684,0-1.38379-0.24023-1.88184-0.7207s-0.74707-1.06543-0.74707-1.75293 c0-0.46289,0.11719-0.89258,0.34961-1.29102c0.23242-0.39648,0.55273-0.70605,0.95996-0.92871s0.8623-0.33398,1.36621-0.33398 c0.31641,0,0.62305,0.04785,0.91602,0.14551c0.29492,0.0957,0.54492,0.22266,0.75,0.37695 C118.56934,25.57617,118.74316,25.77344,118.8877,26.01367z"/>
					<path className="masthed-byline" d="M122.33301,24.89746c0.73633,0,1.34766,0.2666,1.83203,0.7998c0.43945,0.48633,0.66016,1.0625,0.66016,1.72656 c0,0.66797-0.23242,1.25195-0.69922,1.75195c-0.46484,0.5-1.0625,0.74902-1.79297,0.74902 c-0.73438,0-1.33398-0.24902-1.79883-0.74902s-0.69727-1.08398-0.69727-1.75195c0-0.66211,0.21875-1.23633,0.66016-1.72266 C120.98145,25.16602,121.59277,24.89746,122.33301,24.89746z M122.33105,25.5c-0.51172,0-0.95117,0.18848-1.32031,0.56641 c-0.36719,0.37793-0.55078,0.83496-0.55078,1.37109c0,0.3457,0.08398,0.66797,0.25195,0.9668s0.39453,0.5293,0.67969,0.69141 c0.28516,0.16406,0.59766,0.24414,0.93945,0.24414c0.33984,0,0.6543-0.08008,0.9375-0.24414 c0.28711-0.16211,0.51172-0.39258,0.67969-0.69141s0.25195-0.62109,0.25195-0.9668c0-0.53613-0.18555-0.99316-0.55273-1.37109 C123.27832,25.68848,122.83887,25.5,122.33105,25.5z"/>
					<path className="masthed-byline" d="M130.73535,23.1748v6.62695h-0.60742v-0.82129c-0.25781,0.3125-0.54688,0.54883-0.86719,0.70703 s-0.67188,0.2373-1.05273,0.2373c-0.67773,0-1.25391-0.24512-1.73438-0.73535c-0.47852-0.49121-0.7168-1.08789-0.7168-1.79102 c0-0.68945,0.24023-1.27734,0.72461-1.76758c0.48242-0.48828,1.06445-0.7334,1.74414-0.7334 c0.39258,0,0.74805,0.08301,1.06641,0.25098c0.31641,0.16602,0.5957,0.41699,0.83594,0.75098V23.1748H130.73535z  M128.27637,25.49023c-0.3418,0-0.6582,0.08398-0.94922,0.25293c-0.28906,0.16895-0.51953,0.40527-0.69141,0.70898 c-0.16992,0.30371-0.25586,0.62598-0.25586,0.96582c0,0.33594,0.08594,0.6582,0.25977,0.96484 c0.17188,0.30859,0.4043,0.54688,0.69531,0.71875c0.29102,0.1709,0.60352,0.25586,0.9375,0.25586 c0.33594,0,0.6543-0.08398,0.95508-0.25391c0.30273-0.16992,0.5332-0.39941,0.69727-0.68945 c0.16211-0.28906,0.24414-0.61523,0.24414-0.97852c0-0.55273-0.18359-1.01563-0.54688-1.38672 C129.25684,25.67676,128.80957,25.49023,128.27637,25.49023z"/>
					<path className="masthed-byline" d="M136.15332,28.21484l0.51953,0.27344c-0.17188,0.33398-0.36719,0.60352-0.58984,0.80859s-0.47461,0.36133-0.75195,0.4668 c-0.2793,0.10742-0.5957,0.16113-0.94531,0.16113c-0.78125,0-1.39063-0.25488-1.83008-0.7666 c-0.44141-0.51172-0.66016-1.08984-0.66016-1.73438c0-0.60547,0.18555-1.14648,0.55859-1.62109 c0.47266-0.60352,1.10352-0.90527,1.89453-0.90527c0.81641,0,1.4668,0.30957,1.95313,0.92676 c0.3457,0.4375,0.52344,0.98242,0.5293,1.63477h-4.30273c0.01172,0.55566,0.18945,1.01172,0.53125,1.36621 c0.34375,0.35449,0.76758,0.53223,1.27148,0.53223c0.24414,0,0.48047-0.04199,0.71094-0.12695 c0.23047-0.08398,0.42578-0.19629,0.58789-0.33594C135.79004,28.75391,135.96582,28.52734,136.15332,28.21484z  M136.15332,26.92773c-0.08203-0.32813-0.20117-0.58984-0.35938-0.78711c-0.15625-0.19531-0.36328-0.35352-0.62109-0.47461 c-0.25781-0.11914-0.5293-0.17969-0.81445-0.17969c-0.46875,0-0.87305,0.15039-1.21094,0.45313 c-0.24609,0.21875-0.43164,0.54883-0.55859,0.98828H136.15332z"/>
					<path className="masthed-byline" d="M138.58887,28.61523l0.5918,0.28125l-0.89063,1.66113l-0.43555-0.18457L138.58887,28.61523z"/>
					<path className="masthed-byline" d="M142.44629,25.02051h0.65039l1.64258,3.68164l1.5957-3.68164h0.6543l-2.85156,6.53027h-0.64844l0.91992-2.11133 L142.44629,25.02051z"/>
					<path className="masthed-byline" d="M150.04785,24.89746c0.73828,0,1.34766,0.2666,1.83203,0.7998c0.43945,0.48633,0.66016,1.0625,0.66016,1.72656 c0,0.66797-0.23242,1.25195-0.69727,1.75195s-1.0625,0.74902-1.79492,0.74902s-1.33203-0.24902-1.79688-0.74902 c-0.4668-0.5-0.69922-1.08398-0.69922-1.75195c0-0.66211,0.2207-1.23633,0.66211-1.72266 C148.69824,25.16602,149.30957,24.89746,150.04785,24.89746z M150.0459,25.5c-0.51172,0-0.94922,0.18848-1.31836,0.56641 c-0.36719,0.37793-0.55078,0.83496-0.55078,1.37109c0,0.3457,0.08398,0.66797,0.25195,0.9668s0.39453,0.5293,0.67773,0.69141 c0.28711,0.16406,0.59961,0.24414,0.93945,0.24414c0.3418,0,0.6543-0.08008,0.93945-0.24414 c0.28516-0.16211,0.51172-0.39258,0.67969-0.69141s0.25195-0.62109,0.25195-0.9668c0-0.53613-0.18555-0.99316-0.55469-1.37109 S150.55566,25.5,150.0459,25.5z"/>
					<path className="masthed-byline" d="M153.60059,25.02051h0.61328v2.23242c0,0.54492,0.0293,0.91992,0.08789,1.125 c0.08984,0.29297,0.25781,0.52441,0.50391,0.69434c0.25,0.16992,0.54492,0.25488,0.88867,0.25488 c0.3418,0,0.63281-0.08301,0.87305-0.24902c0.23828-0.16406,0.4043-0.38281,0.49609-0.65234 c0.06055-0.18359,0.0918-0.5752,0.0918-1.17285v-2.23242h0.62891v2.34668c0,0.65918-0.07813,1.15625-0.23242,1.49023 c-0.15234,0.33398-0.38477,0.5957-0.69336,0.7832c-0.31055,0.18945-0.69727,0.28418-1.16211,0.28418 c-0.4668,0-0.85547-0.09473-1.16797-0.28418c-0.31055-0.1875-0.54492-0.45117-0.69727-0.79102 c-0.1543-0.33789-0.23047-0.84668-0.23047-1.52637V25.02051z"/>
					<path className="masthed-byline" d="M158.91699,25.02051h0.62891v0.69824c0.1875-0.27539,0.38477-0.48047,0.59375-0.61719 c0.20703-0.13672,0.42383-0.2041,0.65039-0.2041c0.16992,0,0.34961,0.05371,0.54492,0.16309l-0.32227,0.51758 c-0.12891-0.05469-0.23633-0.08301-0.32422-0.08301c-0.20508,0-0.4043,0.08496-0.59375,0.25293s-0.33594,0.42969-0.43555,0.78418 c-0.07617,0.27246-0.11328,0.82324-0.11328,1.65234v1.61719h-0.62891V25.02051z"/>
					<path className="masthed-byline" d="M163.70215,25.02051h0.63281l1.4668,3.47168l1.52539-3.47168h0.11133l1.53516,3.47168l1.49414-3.47168h0.63867 l-2.07617,4.78125h-0.11328l-1.5293-3.42285l-1.52734,3.42285h-0.11523L163.70215,25.02051z"/>
					<path className="masthed-byline" d="M176.59277,25.02051v4.78125h-0.60742v-0.82129c-0.25781,0.3125-0.54688,0.54883-0.86719,0.70703 c-0.32227,0.1582-0.67188,0.2373-1.05273,0.2373c-0.67773,0-1.25586-0.24512-1.73438-0.73535 c-0.47852-0.49121-0.71875-1.08789-0.71875-1.79102c0-0.68945,0.24219-1.27734,0.72656-1.76758 c0.48242-0.48828,1.06445-0.7334,1.74414-0.7334c0.39258,0,0.74805,0.08301,1.06445,0.25098 c0.31836,0.16602,0.59766,0.41699,0.83789,0.75098v-0.87891H176.59277z M174.13379,25.49023 c-0.34375,0-0.6582,0.08398-0.94922,0.25293c-0.28906,0.16895-0.51953,0.40527-0.69141,0.70898 c-0.16992,0.30371-0.25586,0.62598-0.25586,0.96582c0,0.33594,0.08594,0.6582,0.25781,0.96484 c0.17383,0.30859,0.40625,0.54688,0.69727,0.71875c0.28906,0.1709,0.60156,0.25586,0.93555,0.25586 c0.33789,0,0.65625-0.08398,0.95703-0.25391s0.5332-0.39941,0.69531-0.68945c0.16406-0.28906,0.24414-0.61523,0.24414-0.97852 c0-0.55273-0.18164-1.01563-0.54688-1.38672C175.11426,25.67676,174.66504,25.49023,174.13379,25.49023z"/>
					<path className="masthed-byline" d="M177.46191,25.02051h0.65039l1.64258,3.68164l1.5957-3.68164h0.65625l-2.85352,6.53027h-0.64844l0.91992-2.11133 L177.46191,25.02051z"/>
					<path className="masthed-byline" d="M183.47754,28.83496c0.15039,0,0.27734,0.05273,0.38281,0.1582s0.1582,0.23438,0.1582,0.38672 c0,0.14941-0.05273,0.27832-0.1582,0.38379c-0.10547,0.10742-0.23242,0.16113-0.38281,0.16113 c-0.14844,0-0.27539-0.05371-0.38086-0.16113c-0.10742-0.10547-0.1582-0.23438-0.1582-0.38379 c0-0.15234,0.05078-0.28125,0.1582-0.38672C183.20215,28.8877,183.3291,28.83496,183.47754,28.83496z"/>
					<path className="trademark-symbol" d="M187.99121,21.52539v0.60156h-1.33984v3.18652h-0.66016v-3.18652h-1.33203v-0.60156H187.99121z M193.25098,21.52539 v3.78809h-0.6582v-3.12012l-1.41406,3.12012h-0.43359l-1.36914-3.12012v3.12012h-0.6543v-3.78809h1.06641l1.17578,2.62305 l1.24219-2.62305H193.25098z"/>
				</g>
			</g>
			</svg>







			{/* { props.type!=='device' && <div className="brand-masthead">
				<div className="brand-masthead-primary">CODEBASE</div>
				<div className="brand-masthead-suplementary">
					<div className="brand-masthead-secondary">Our Code</div>
					<div className="brand-masthead-tertiary">Your Way™</div>
				</div>
			</div>
			} */}
		</div>

	);
}
export default Logo;