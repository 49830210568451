import React from "react";
import { useKeenSlider } from "keen-slider/react";
import { Link } from 'react-router-dom';
import "keen-slider/keen-slider.min.css";

import {ReactComponent as DeConsultLogo} 		from "../../svg/logos/De-Consult-Logo-V09.svg";
import {ReactComponent as StephenWoolleyLogo} 	from "../../svg/logos/Stephen-Woolley-Logo-V12.svg";
import {ReactComponent as IIAULogo} 			from "../../svg/logos/iiau-Logo-V04.svg";
import {ReactComponent as IMGLogo} 				from "../../svg/logos/IMG-Plecyderm-V03.svg";
import {ReactComponent as LTLLogo} 				from "../../svg/logos/LifeTimeLine-Slide-Bubble-V01-Vertical.svg";
import {ReactComponent as AlsingStreetLogo} 				from "../../svg/logos/Asling-Street-Logo-V03.svg";

import Logo from '../Logo';

import "./Brands.scss";

export default function Brands(props){
	const [pause, setPause] = React.useState(false);
	const timer = React.useRef();
	const idle = 2500;
	const moving = 3000;
	const cycle = idle + moving;
	const [sliderOptions, setSliderOptions] = React.useState(false);
	const [sliderRef, slider] = useKeenSlider(sliderOptions);

	// Placing options in a timeout (inside an onload effect), inits slider after page hydration and is a workaround for the bugs linked to React.Lazy
	// See \Dropbox\CODEBASE\PROJECTS\Keen-Slider\Keen-Slider-Analysis-V01.xlsx
	React.useEffect(() => {
		setTimeout(() => {
			console.log('Set Slider Options');
			setSliderOptions({
				spacing: 15,
				centered: false,
				slidesPerView: 1,
				mode: "free-snap",
				breakpoints: {
					"(min-width: 600px)": 	{	slidesPerView: 2,	mode: "free-snap"	},
					"(min-width: 990px)": 	{	slidesPerView: 3,	mode: "free-snap"	},
					"(min-width: 1200px)": 	{	slidesPerView: 5,	mode: "free-snap"	}
				},
				loop: true,
				duration: moving,
				dragStart: () => {
				  setPause(true)
				},
				dragEnd: () => {
				  setPause(false)
				},
		  })
		}, 250)
	  }, []);

	React.useEffect(() => {
		sliderRef.current.addEventListener("mouseover", () => {
			setPause(true);
		});
		sliderRef.current.addEventListener("mouseout", () => {
			setPause(false);
		});
	}, [sliderRef]);

	React.useEffect(() => {
		timer.current = setInterval(() => {
		if (!pause && slider) {
			slider.next();
		}
		}, cycle);
		return () => {
			clearInterval(timer.current);
		};
	}, [pause, slider, cycle]);




  return (

    <div ref={sliderRef} className="keen-slider keen-slider--brand keen-slider--brand-with-heading">
		<Link to="/de-consult/" className="keen-slider__slide slide-frame dct-frame">
			<DeConsultLogo />
		</Link>
		<Link to="/stephen-woolley/" className="keen-slider__slide slide-frame sww-frame">
			<StephenWoolleyLogo />
		</Link>
		<Link to="/lifetimeline/" className="keen-slider__slide slide-frame ltl-frame">
			<LTLLogo />
		</Link>
		<Link to="/intelligent-information/" className="keen-slider__slide slide-frame iia-frame">
			<IIAULogo />
		</Link>
		<Link to="/in-memoriam/" className="keen-slider__slide slide-frame img-frame">
			<IMGLogo />
		</Link>
		<Link to="/asling-street/" className="keen-slider__slide slide-frame ast-frame">
			<AlsingStreetLogo />
		</Link>
		<Link to="/codebase/" className="keen-slider__slide slide-frame cbs-frame">
			<Logo />
		</Link>
    </div>
  );
};