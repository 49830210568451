import './Ribbon.scss';

function Ribbon( { Tag, children } ) {
	const HTag = Tag.toLowerCase();
	return (
		<section className="title-ribbon">
			<div className="flx box">
				<div className="cell">
					<HTag>{children}</HTag>					
				</div>
			</div>
		</section>
	);
}

export default Ribbon;