import React from 'react';
import { ReactComponent as Spinner } from '../../svg/spinners/oval.svg';
import './Loader.scss';


function Loader(props) {
  return (
	<section className="loader">
		<Spinner />
	</section>
  );
}
export default Loader;