import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import Ribbon from '../../components/Ribbon/Ribbon.js'

import './About.scss';

function About() {
	return (
		<main>
			<Ribbon Tag="H6"><span>Meticulous</span> attention to detail. <span>Consistent</span> and <span>dependable</span> results.</Ribbon>
			<section>
				<div className="flx box">
					<div className="cell">
						<h1><span>About</span> CODEBASE<span className="registered-trademark-symbol">®</span></h1>					
					</div>
				</div>
			</section>
			<section className="">
				<div className="flx box">
					<div className="cell flx-cell cell--third about-us-img" />
					<div className="cell flx-cell cell--two-thirds">
						<h2>Rawdyn Nutting</h2>
						<h3>Founder</h3>		
						<p>After many years working in leading commercial software development roles, I became frustrated at the industry 
						standard of pushing work out knowing it was unfinished and accepting the cost of the fall out as cheaper than developing the project successfully. This led me to create CODEBASE.</p>

						<p>With a background in graphic design, having worked several years at one of Melbourne's leading digital labs, I worked on bringing together the design and development 
						experience gained over that time to focus CODEBASE on creating better designed and built software solutions for our clients. Easy to use software that was reliable and enduring.</p>
					
						{/* <CallToAction ctaLabel={["Tell us what you need"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	 */}
					</div>
				</div>
			</section>

			<section className="last top-gap">
				<div className="flx box">
					<div className="cell flx-cell cell">
						<h2>Philosophy</h2>
						<h3>Our Code, Your Way™</h3>		
						<p>Our goal is to abstract the challenging parts of software design, implementation and on-going maintenance away from our clients to allow them to get on with their work.</p>

						<p>Our clients can call or email us at any time and expect a response as if we are their internal dev team. We work hard on building lasting business relationships with our clients; 
						some of whom we have been working with for more than two decades.</p>

						<p>We will always recommend what is best for our client and project. This includes when we are not the best fit for their requirements. 
						</p>						
						<p>Over the years we have built many successful projects both internal and for clients; some of which are still going strong decades after release.
						</p>	
						<CallToAction ctaLabel={["Tell us what you need"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
					<div className="cell flx-cell cell about-us-collaboration-img" />
				</div>
			</section>
		</main>
	);
}

export default About;