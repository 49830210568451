import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Slider.scss";
import CallToAction from "../CallToAction/CallToAction";

export default function Slider(props){	
	const [pause, setPause] = React.useState(false);
	const timer = React.useRef();
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const [sliderRef, slider] = useKeenSlider({
		loop: true,
		duration: 2000,
		initial: props.initialSlide,
		autoHeight: false,
		dragStart: () => { setPause(true); },
		dragEnd: () => { setPause(false); },
		slideChanged(s) { setCurrentSlide(s.details().relativeSlide); },
 	 });

	React.useEffect(() => {
		sliderRef.current.addEventListener("mouseover", () => {
			setPause(true);
		});
		sliderRef.current.addEventListener("mouseout", () => {
			setPause(false);
		});
	}, [sliderRef]);

	React.useEffect(() => {
		timer.current = setInterval(() => {
			if (!pause && slider) {
				slider.next();
			}
		}, process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? 7000 : 7000 ); // push out first number for slow transition in dev
		return () => {
			clearInterval(timer.current);
		};
	}, [pause, slider, currentSlide]);

	const sliderArray = [
		{	sliderClassName:	'peace-of-mind-snowscape',
			headings:			[	{	level: 2,	className: '',			text: 'Peace of mind that your'				},
									{	level: 2,	className: 'bolded',	text: 'Software Works'						},
									{	level: 3,	className: '',			text: 'End to End Product Confidence'		},	],			
			ctaClassName: 		'cta--contrast',
			ctaLabel: 			'Ask Us How',
			ctaDestination: 	'/contact/'
		},
		{	sliderClassName:	'experience-counts',
			headings:			[	{	level: 2,	className: '',			text: 'Decades of<span class="b2"> </span>Experience'				},
									{	level: 2,	className: 'bolded',	text: 'Designing<span class="b2"> </span>& Building'					},
									{	level: 3,	className: '',			text: 'Custom Solutions<span class="b2"> </span>for Our Clients'	},	],			
			ctaClassName: 		'cta--contrast',
			ctaLabel: 			'Learn More',
			ctaDestination: 	'/software/'
		},
		{	sliderClassName:	'robots-are-great',
			headings:			[	{	level: 2,	className: '',			text: '<span>Custom</span><span class="b2"> </span>Designed'			},
									{	level: 2,	className: '',			text: '<span>reliable</span><span class="b2"> </span>software'		},
									{	level: 3,	className: '',			text: 'Dependable Tools<span class="b3"> </span>to Help<span class="b2"> </span>Your<span class="b3"> </span>Business Succeed</span>' },	],
			ctaClassName: 		'cta--support-bright',
			ctaLabel: 			'Find Out More',
			ctaDestination: 	'/software/'
		},
		{	sliderClassName:	'training-software',
			headings:			[	{	level: 2,	className: '',			text: 'Build, Deliver<span class="b2"> </span>& Monitor'	},
									{	level: 2,	className: '',			text: '<span>Online<span class="b2"> </span>Training</span>'	},
									{	level: 3,	className: '',			text: 'With Our Skillbase<span class="b2"> </span>Learning Platform'								},	],
			ctaClassName: 		'cta--support-bright--focus-bright',
			ctaLabel: 			'Find Out More',
			ctaDestination: 	'/contact/'
		},
	]


	const drawHeadings = (h) => {
		if(Array.isArray(h)) {
			return (
				h.map((iter, index) => {
					const usesInnerHTML = /<\/?[a-z][\s\S]*>/i.test(iter.text);
					const HeadingTag = `h${iter.level}`;
					if(usesInnerHTML) {
						return (
							<HeadingTag key={index} className={iter.className} dangerouslySetInnerHTML={{ __html: iter.text }}></HeadingTag>
						)
					} else {
						return (
							<HeadingTag key={index} className={iter.className}>{iter.text}</HeadingTag>
						)
					}
				})
			);			
		}
		return;
	}
	
	const drawSliders = () => {
		return (
			<div ref={sliderRef} className="keen-slider keen-slider--banner">		
				{ sliderArray.map((iter, index) => {
					return (							
						<div key={index} className={`keen-slider__slide slider-image-background ${iter.sliderClassName} ${currentSlide===index ? 'active' : ''}`}>							
							<div className="headings-box">
								{drawHeadings(iter.headings)}
								<CallToAction className={iter.ctaClassName} ctaLabel={iter.ctaLabel} ctaDestination={iter.ctaDestination}></CallToAction>
							</div>
						</div>
					)
				}) };
			</div>
		);
	}

	return (
		<>
		<div className="navigation-wrapper">			
			{ sliderArray && drawSliders() }			
			{ slider && (
				<>
					<ArrowLeft
						onClick={e => e.stopPropagation() || slider.prev()}
						//disabled={currentSlide === 0}
						/>
					<ArrowRight
						onClick={e => e.stopPropagation() || slider.next()}
						//  disabled={currentSlide === slider.details().size - 1}
						/>
				</>
			)}
		</div>
		{ slider && (
			<div className="dots">

					
				{ sliderArray.map((iter, idx) => {
					return (
						<button
							key={idx}
							onClick={() => {
								slider.moveToSlideRelative(idx);
							}}
							aria-label={`Scroll to Banner ${idx}`}
							className={`dot ${iter.sliderClassName}--dot ${(currentSlide === idx ? " active" : "")}`}
						/>
					);
				}) }

			</div>
		) }
		</>
	);
};

function ArrowLeft(props) {
	const disabeld = props.disabled ? " arrow--disabled" : "";
	return (
		<svg
			onClick={props.onClick}
			className={"arrow arrow--left" + disabeld}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			>
			<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
		</svg>
	);
}

function ArrowRight(props) {
	const disabeld = props.disabled ? " arrow--disabled" : "";
	return (
		<svg
			onClick={props.onClick}
			className={"arrow arrow--right" + disabeld}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			>
			<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
		</svg>
	);
}
