import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import BreadCrumbTrail from '../../components/BreadCrumbTrail/BreadCrumbTrail.js'
import { useLocation } from 'react-router-dom'

import {ReactComponent as Tax8Logo} 			from "../../svg/logos/TAX8-Logo-V01.svg";

import './GraphicDesign.scss';



function Tax8() {
	const location = useLocation();
	const locationName = location.pathname.replace(/\//g, "");
	// console.log(`DCT ${locationName}`);
	const brandData = {		
		'tax8': {
			brandLabel: 		'TAX8',
			brandHeading: 		<h1><span>TAX8</span></h1>,
			brandSubHeading: 	<h2>A Personal Connection</h2>,
			brandComponent:		Tax8Logo,
			brandDescription: (<>
								<p>A perfectly short brand emphasising simplicity and clarity in an often complex field.</p>
								<p>The principal, Kate, has, for many years, signed her name as ‘K8’ so we took advantage of that to create 
								TAX8 and leveraged the greater than and less than symbols to divide the 'X' thereby forming the 'K' and placing ‘K8’ in the brand.</p>
							
								</>
								)
		}
	};
	

	if( !(locationName in brandData)) {
		return <h1>Content Not Found</h1>;
	}
	const BrandComponent = brandData[locationName].brandComponent;
	const bctArray = [ { bctLink: '/graphic-design/', bctLabel: 'Graphic Design' }, { bctLink: `/${locationName}/`, bctLabel: brandData[locationName].brandLabel } ]

	return (
		<main>
			<BreadCrumbTrail bctArray={bctArray} />

			<section className="last">				
				<div className="tax8-wrap box">
					<div className="cell flx-cell tax8-box">
						<BrandComponent  className="brand-tax8" />
					</div>
				</div>
			</section>
		</main>
	);
}

export default Tax8;