import React from 'react';
import { Link } from 'react-router-dom'
import Ribbon from '../../components/Ribbon/Ribbon.js'

import './Sitemap.scss';

function Sitemap() {
	return (
		<main>
			<Ribbon Tag="H6"><span>Links to everything</span> on our website.</Ribbon>
					
			<section className="last">
				<div className="flx box">
					<div className="cell">
						<h1><span>Sitemap</span></h1>					
					</div>
				</div>
				<div className="flx box sitemap">
					<Link to="/">Homepage</Link>
					<Link to="/services/">Services</Link>
						<Link to="/software/" className="sub">Software Development</Link>
						<Link to="/graphic-design/" className="sub">Graphic Design</Link>
							<Link to="/de-consult/" className="sub-sub">Brand Study: De-consult</Link>			
							<Link to="/stephen-woolley/" className="sub-sub">Brand Study: Stephen Woolley Physiotherapy</Link>
							<Link to="/lifetimeline/" className="sub-sub">Brand Study: LifeTimeLine</Link>
							<Link to="/intelligent-information/" className="sub-sub">Brand Study: Intelligent Information</Link>
							<Link to="/inmemoriam/" className="sub-sub">Brand Study: In Memoriam</Link>
							<Link to="/asling-street/" className="sub-sub">Brand Study: Asling Street Property Group</Link>
							<Link to="/codebase/" className="sub-sub">Brand Study: CODEBASE</Link>
						<Link to="/websites-and-seo/" className="sub">Websites</Link>
					<Link to="/about/">About</Link>
					<Link to="/contact/">Contact Form and Map</Link>
					<Link to="/privacy-policy/">Privacy Policy</Link>
					<Link to="/sitemap/">Sitemap (you are here now)</Link>
				</div>
			</section>
		</main>
	);
}

export default Sitemap;