import React from 'react';
import Teasers from '../../components/Teasers/Teasers.js';
import Ribbon from '../../components/Ribbon/Ribbon.js'

function Services(props) {
  return (
	<main>
		<Ribbon Tag="H1">Our Services provide the <span>perfect</span> combination of <span>expertise</span> and <span>attitude</span>.</Ribbon>
		<Teasers />
	</main>
  );
}
export default Services;